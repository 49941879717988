import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
// State
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../State/slices/user-slice";
// Components
import Page from "../../Components/Page";
import ServiceUserImage from "../../Components/ServiceUserImage";
import SecondaryButton from "../../Components/SecondaryButton";
import PageTitle from "../../Components/PageTitle";
// API
import { postUserNotifications } from "@intelligentlilli/api-layer";
// Utils
import { getNotificationsForAGivenDate } from "../../Services/Utils";
import {
  getInitials,
  getServiceUserById,
  userHasReadNotification,
} from "@intelligentlilli/lilli-utils";
import { subDays, isSameDay, format } from "date-fns";

const markAsRead = (unreadNotification3) => {
  const notificationsMarkedAsRead =
    unreadNotification3?.map((unreadNotification3) => {
      return {
        ...unreadNotification3,
        hasBeenReadLocally: true,
      };
    }) || [];
  return notificationsMarkedAsRead;
};

const Notifications = () => {
  const navigate = useNavigate();

  // redux state
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const server = useSelector((state) => state.session.server);
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const notifications = useSelector(
    (state) => state.user?.notifications?.notificationsData || []
  );
  const DAYS_TO_SHOW = 7;
  const datesInAGivenPeriod = [new Date()];
  for (let i = 1; i < DAYS_TO_SHOW; i++) {
    datesInAGivenPeriod.push(subDays(new Date(), i));
  }

  // When the user leaves the notifications page send off readAt API calls for all notifications
  useEffect(() => {
    // callback function when component unmounts
    return () => {
      // An async function for marking all unread notifications as read
      async function markNotifications() {
        const unreadNotifications = notifications?.filter(
          (notification) => !notification?.hasBeenReadLocally
        );
        const promises = unreadNotifications.map((unreadNotification) =>
          postUserNotifications(
            server,
            user.key,
            unreadNotification.hubId,
            unreadNotification.analysisDate,
            new Date().toISOString(),
            "web"
          )
        );
        await Promise.all(promises).then(() => {
          if (unreadNotifications.length > 0) {
            console.log("mark notifications as read and dispatch to redux!");
            dispatch(
              updateUser({
                notifications: {
                  notificationsData: markAsRead(unreadNotifications),
                  ...unreadNotifications,
                },
              })
            );
          }
        });
      }
      if (notifications) {
        markNotifications();
      }
    };
  }, [dispatch, server, user.key, notifications]);

  return (
    <Page className={styles.page}>
      <PageTitle title="Notification Centre" />
      <p className={styles.explanation}>
        Here you can view all your notifications. Notifications include: Any
        kind of high risk behaviour from any users and reports becoming ready to
        view.
      </p>
      <p className={styles.courier_notification}>
        Courier sends notifications on behalf of Lilli. See{" "}
        <a
          href="https://www.courier.com/privacy/"
          className={styles.courier_link}
        >
          Courier's Privacy Policy
        </a>{" "}
        for more information.
      </p>

      <div className={styles.content}>
        {datesInAGivenPeriod.map((date, index) => {
          const notificationsToShow = getNotificationsForAGivenDate(
            date,
            notifications
          );
          if (notificationsToShow?.length > 0) {
            return (
              <div key={date} className={styles.notification_date_container}>
                <div className={styles.notifications_for_one_day}>
                  {isSameDay(new Date(), date)
                    ? `Today, ${format(date, "d MMM")}`
                    : isSameDay(subDays(new Date(), 1), date)
                      ? `Yesterday, ${format(date, "d MMM")}`
                      : `${index} days ago, ${format(date, "d MMM")}`}
                </div>
                {notificationsToShow?.map((notification) => {
                  const notificationSentences = notification.sentences
                    .filter((sentence) => !sentence.includes(" expected"))
                    .map((sentence) => {
                      const serviceUser = getServiceUserById(
                        serviceUsers,
                        notification.hubId.toString()
                      );
                      const allTabs = [
                        "sleep",
                        "temperature",
                        "movement",
                        "sustenance",
                        "independence",
                        "bathroom",
                      ];
                      const tab = allTabs.filter((tab) =>
                        sentence.includes(tab)
                      );
                      return (
                        <div className={styles.notification}>
                          <div
                            data-private
                            className={styles.notification_body}
                          >
                            {!userHasReadNotification(
                              notification,
                              `${user.key}`
                            ) &&
                              !notification?.hasBeenReadLocally && (
                                <div className={styles.notification_unread} />
                              )}
                            <ServiceUserImage
                              small
                              initials={getInitials(serviceUser?.userstring)}
                              profilePhotoURL={serviceUser?.photo}
                              altText={`Profile photo for ${serviceUser?.userstring}`}
                              className={styles.notification_image}
                            />
                            <div>{sentence}</div>
                          </div>
                          <SecondaryButton
                            onClick={() =>
                              navigate(
                                `/lilli-users/${notification.hubId}?tab=${tab}&view=day&start=${notification.analysisDate}&end=${notification.analysisDate}`
                              )
                            }
                            className={styles.notification_button}
                          >
                            View profile
                          </SecondaryButton>
                        </div>
                      );
                    });
                  return notificationSentences;
                })}
              </div>
            );
          } else {
            return <></>;
          }
        })}
      </div>
    </Page>
  );
};

export default Notifications;
