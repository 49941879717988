import { useState, useEffect } from "react";
import { SERVER } from "../config";
import { getCheckAuthState } from "@intelligentlilli/api-layer";
// State
import { setUser } from "../../State/slices/user-slice";
import { setServiceUsers } from "../../State/slices/serviceUsers-slice";
import { useDispatch } from "react-redux";

export const useNativeInstallAuth = ({ path }) => {
  const dispatch = useDispatch();
  const [auth, setAuth] = useState(null);
  console.log("auth:", auth);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isNative = !!auth;
  console.log("isNative:", isNative);

  useEffect(() => {
    const token = window.sessionStorage.getItem("token");
    setAuth(token);
  }, []);

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const response = await fetch(`${SERVER}/${path}`, {
          method: "GET",
          credentials: "include",
          headers: {
            "install-auth": auth,
          },
        });
        console.log("response of cloudflare", response);
        if (response.ok) setIsLoggedIn(true);
      } catch (error) {
        console.log("error", error);
      }
    };
    if (auth) {
      console.log("*---* useEffect fetchAuth - auth:", auth);
      // fetch auth every 15 seconds
      fetchAuth();
      const interval = setInterval(() => {
        fetchAuth();
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [auth, path]);

  // This useEffect is only for React Native, not for web.
  useEffect(() => {
    if (isNative && isLoggedIn) {
      console.log(
        "**** useEffect:",
        "isNative:",
        isNative,
        "isLoggedIn",
        isLoggedIn
      );
      const getAuthData = async () => {
        try {
          const response = await getCheckAuthState(SERVER, "mobile-app");
          console.log("response of auth", response);
          // In the web app managers see all SUs in the org. On the native app install they only see the SUs they are associated with
          if (response.ok && path === "install/native/start") {
            dispatch(setUser(response?.body));
            dispatch(setServiceUsers(response?.body?.hubs));
          }
        } catch (error) {
          console.log(
            "web app getAuthData error for mobile-app:",
            "path:",
            path,
            "error:",
            error
          );
        }
      };
      getAuthData();
    }
  }, [isNative, isLoggedIn, dispatch, path]);

  return { isNative };
};
